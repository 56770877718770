<template>
  <el-drawer
    ref="drawer"
    :visible="isVisible"
    size="35rem"
    :direction="$direction === 'rtl' ? 'ltr' : 'rtl'"
    :modal="false"
    :wrapper-closable="false"
    :show-close="false"
    @closed="$emit('on-drawer-closed')"
  >
    <template #title>
      <div class="header-section">
        <div class="header">
          <Button class="header-close-icon" type="icon" @click="closeDrawer">
            <DrawerCloseIcon />
          </Button>
          <div class="title">{{ title }}</div>
        </div>
        <div class="divider"></div>
      </div>
    </template>

    <template #default>
      <slot />
    </template>
  </el-drawer>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';

import { Button } from '@/modules/core';
import { DrawerCloseIcon } from '@/assets/icons';

export default {
  name: 'AlertsDrawer',
  components: { DrawerCloseIcon, Button },
  props: {
    title: {
      type: String,
      default: '',
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    alert: {
      type: Object,
      default: null,
    },
  },
  emits: ['close-drawer', 'on-drawer-closed'],
  setup(props, { emit }) {
    const outSideOfDrawerClick = ref(0);
    const drawer = ref(null);
    const handledByUser = computed(() => props.alert?.handledByUser);

    const closeDrawer = () => {
      emit('close-drawer');
    };

    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        closeDrawer();
      }
    };

    let clickTimeout = null;
    const handleClickOutside = (event) => {
      if (drawer.value && event.target.role === 'document') {
        outSideOfDrawerClick.value++;

        if (clickTimeout) {
          clearTimeout(clickTimeout);
        }

        clickTimeout = setTimeout(() => {
          outSideOfDrawerClick.value = 0;
        }, 1000);

        if (outSideOfDrawerClick.value >= 2) {
          closeDrawer();
        }
      }
    };

    onMounted(() => {
      document.addEventListener('click', handleClickOutside);
      window.addEventListener('keydown', handleEscKey);
    });

    onBeforeUnmount(() => {
      document.removeEventListener('click', handleClickOutside);
      window.removeEventListener('keydown', handleEscKey);
    });

    return {
      drawer,
      handledByUser,
      closeDrawer,
    };
  },
};
</script>

<style scoped lang="scss">
.header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;

    .header-close-icon {
      [dir='ltr'] & {
        transform: rotate(180deg);
      }
    }
  }

  .title {
    color: #131416;
    text-align: right;

    font-family: Rubik;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.75rem;
    letter-spacing: -0.015rem;
  }
}

.close-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
