import { render, staticRenderFns } from "./AlertsDrawer.vue?vue&type=template&id=eed786cc&scoped=true"
import script from "./AlertsDrawer.vue?vue&type=script&lang=js"
export * from "./AlertsDrawer.vue?vue&type=script&lang=js"
import style0 from "./AlertsDrawer.vue?vue&type=style&index=0&id=eed786cc&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eed786cc",
  null
  
)

export default component.exports