<template>
  <div class="alerts-empty-content">
    <div class="alerts-empty-icons">
      <CheckCircleFullIcon v-if="!isHandled" class="checkmark-icon" fill="#11874F" :size="24" />
      <EmptyAlertsIcon class="connection-icon" :fill="isHandled ? '#9295A5' : undefined" />
    </div>
    <span class="alerts-empty-text">
      {{ $t(`alert.alertsPage.table.empty.handled.${isHandled}`) }}
    </span>
  </div>
</template>

<script lang="js">
import { CheckCircleFullIcon, EmptyAlertsIcon } from '@/assets/icons';

export default {
  components: { EmptyAlertsIcon, CheckCircleFullIcon },
  props: {
    isHandled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.alerts-empty-icons {
  position: relative;
  width: 5rem;
  height: 5rem;

  .checkmark-icon {
    position: absolute;
    z-index: 2;
    top: -2px;
    left: -7px;
  }

  .connection-icon {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

.alerts-empty-content {
  margin: 5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 1rem;
}

.alerts-empty-text {
  color: #9295a5;
  text-align: center;
  font-family: Rubik;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}
</style>
